import React, { useState, useEffect, useRef, useImperativeHandle } from "react";
import { useNavigate } from "react-router-dom"; // For navigation
import app from "../firebase/firebase";
import { getDatabase, set, push, ref } from "firebase/database";
import QueryForm from "../queryForm";
import { leadSubmited } from "../Utils/FacebookPixelEvents";

const CodForm = ({ productData }) => {
  const [orderData, setFormData] = useState({
    fullName: "",
    city: "",
    address: "",
    country: "",
    phone: "",
    totalPrice: 0,
    product: productData,
    utm_campaign: "",
    utm_content: "",
    utm_medium: "",
    utm_term: "",
  });
  const navigate = useNavigate();
  const [orderDate, setOrderDate] = useState("");
  const [orderSummary, setOrderSummary] = useState({});
  const formRef = useRef(null);
  const [currency, setCurrency] = useState(null);
  const [errors, setErrors] = useState({});
  const [marketsInfo, setmMarketsInfo] = useState({
    SAR: {
      symbol: "ر.س",
      countryName: { en: "Saudi Arabia", ar: "المملكة العربية السعودية" },
    },
    AED: {
      symbol: "د.إ",
      countryName: {
        en: "United Arab Emirates",
        ar: "الإمارات العربية المتحدة",
      },
    },
    BHD: {
      symbol: ".ب.د",
      countryName: { en: "Bahrain", ar: "البحرين" },
    },
    MAD: {
      symbol: "د.م.",
      countryName: { en: "Morocco", ar: "المغرب" },
    },
    OMR: {
      symbol: "ر.ع.",
      countryName: { en: "Oman", ar: "عمان" },
    },
    QAR: {
      symbol: "ر.ق",
      countryName: { en: "Qatar", ar: "قطر" },
    },
    KWD: {
      symbol: "د.ك",
      countryName: { en: "Kuwait", ar: "الكويت" },
    },
  });

  useEffect(() => {
    const fetchCurrency = async () => {
      const response = await fetch(
        "https://app-q5lju5cj2q-uc.a.run.app/api/currency"
      );
      const data = await response.json();
      setCurrency(data.currency);
    };
    fetchCurrency();
    //setting orderDate
    const currentDate = new Date();

    // Extract the date components
    const day = String(currentDate.getDate()).padStart(2, "0");
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const year = currentDate.getFullYear();

    // Extract the time components
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");

    // Combine them into the desired format
    const formattedDate = `${day}/${month}/${year}:${hours}:${minutes}`;

    setOrderDate(formattedDate);
  }, []);
  const handleUtmsUpdate = (utmName, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [utmName]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Validate all fields
    const newErrors = {};
    if (!orderData.fullName) newErrors.name = "الاسم مطلوب";
    if (!orderData.phone) newErrors.phone = "الهاتف مطلوب";
    if (!orderData.city) newErrors.city = "المدينة مطلوبة";
    if (!orderData.country) newErrors.country = "البلد مطلوب";

    // Set errors if any
    setErrors(newErrors);
    console.log(productData);
    function generateRandomOrderId() {
      // Generate a random number and format it to 4 digits
      const randomId = `#${Math.floor(1000 + Math.random() * 9000).toString()}`;
      return randomId;
    }
    // Check if there are no errors
    if (Object.keys(newErrors).length === 0) {
      let totalPrice = 0;
      if (productData.withBundle) {
        totalPrice = orderData.product.selectedBundle.price[currency];
      } else {
        totalPrice =
          orderData.product.quantity * orderData.product.pricing[currency];
      }

      const newOrder = {
        orderId: generateRandomOrderId(),
        productName: orderData.product.name,
        OrderDate: orderDate,
        fullName: orderData.fullName,
        country: marketsInfo[currency].countryName.en,
        sku: "",
        quantity: orderData.product.quantity,
        bundleName: orderData.product.selectedBundle.name,
        productPrice: orderData.product.pricing[currency],
        phone: orderData.phone,
        address: orderData.address,
        totalPrice: totalPrice,
        utm_campaign: orderData.utm_campaign,
        utm_content: orderData.utm_content,
        utm_medium: orderData.utm_medium,
        utm_term: orderData.utm_term,
      };

      setOrderSummary(newOrder);
      // You can do something with the form data here, such as saving to a database or API
      // Redirect to Thank You page
      saveData(newOrder);
      navigate("/thank-you", {
        state: {
          orderSummary: newOrder,
          props: {
            currencySymbol: marketsInfo[currency].symbol,
            withBundle: productData.withBundle,
          },
        },
        replace: true,
      });
      console.log(newOrder, "summary");
      console.log(productData.withBundle, " cod form bundle");
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(value, "triggered");
    if (e.target.value) {
      setErrors({ ...errors, [e.target.name]: "" });
    }

    setFormData({
      ...orderData,
      [name]: value,
    });
  };

  const handleBundleChange = (event) => {
    const selectedBundleId = parseInt(event.target.value);
    // Find the selected bundle object from bundles array
    const selectedBundle = orderData.product.bundleData.find(
      (bundle) => bundle.id === selectedBundleId
    );

    // Update the state with the selected bundle
    setFormData((prevState) => ({
      ...prevState,
      product: {
        ...prevState.product,
        selectedBundle: selectedBundle,
      },
    }));
  };
  const saveData = (data) => {
    const db = getDatabase(app);
    const newDocRef = push(ref(db, productData.slug + "/orders"));
    set(newDocRef, data)
      .then(() => {
        leadSubmited();
      })
      .catch((error) => {
        alert("error: ", error.message);
      });
  };
  return (
    <div>
      <QueryForm reset={false} onUtmUpdate={handleUtmsUpdate} />
      <div className="form-container">
        <form onSubmit={handleSubmit} method="post" noValidate>
          <div className="prices-container">
            {orderData.product.withBundle && (
              <div className="bundle-price-container">
                <div className="compared-price price">
                  {(orderData.product.selectedBundle.price[currency] *
                    orderData.product.discount *
                    4) /
                    100}
                </div>
                <div className="main-price price">
                  <div>
                    {orderData.product.selectedBundle.price[currency]}
                  </div>
                  <div>{marketsInfo[currency]?.symbol}</div>
                </div>
              </div>
            )}
            {!orderData.product.withBundle && (
              <p className="prices">
                {" "}
                {marketsInfo[currency]?.symbol}{" "}
                {orderData.product.pricing[currency]}
              </p>
            )}
          </div>
          {orderData.product.withBundle && (
            <div className="bundles-container">
              <h4 className="heading">إختر العرض المناسب لك </h4>
              <div className="radio-group">
                {orderData.product.bundleData.map((bundle) => (
                  <label key={bundle.id} className="custom-radio">
                    <p className="bundle-option">
                      {" "}
                      <div className="name">{bundle.name}</div>
                      <div className="bundle-price">
                        {bundle.price[currency]}
                        {marketsInfo[currency]?.symbol}
                      </div>{" "}
                    </p>{" "}
                    <input
                      type="radio"
                      onChange={handleBundleChange}
                      name="bundle"
                      value={bundle.id}
                      checked={
                        orderData.product.selectedBundle?.id === bundle.id
                      }
                    />
                    <span className="radio-mark"></span>
                  </label>
                ))}
              </div>
            </div>
          )}

          <div className="order-infos">
            <h4 className="heading">أدخل معلوماتك - التوصيل بالمجان</h4>
            <div className="input-container">
              <input
                onChange={handleChange}
                required
                type="text"
                placeholder="الإسم الكامل"
                name="fullName"
                value={orderData.fullName}
                className={errors.name ? "error-input" : ""}
              />
              {errors.name && (
                <span className="error-message">{errors.name}</span>
              )}
            </div>

            {/* <input
              onChange={handleChange}
              required
              type="text"
              placeholder="العنوان"
              name="address"
              value={orderData.address}
            className={errors.address ? 'error-input' : ''}
            /> */}
            <div className="input-container">
              <input
                onChange={handleChange}
                required
                type="text"
                placeholder="المدينة"
                name="city"
                value={orderData.city}
                className={errors.city ? "error-input" : ""}
              />
              {errors.city && (
                <span className="error-message">{errors.city}</span>
              )}
            </div>
            <div className="input-container">
              <input
                onChange={handleChange}
                required
                type="text"
                placeholder="الدولة"
                name="country"
                value={orderData.country}
                className={errors.country ? "error-input" : ""}
              />
              {errors.country && (
                <span className="error-message">{errors.country}</span>
              )}
            </div>
            <div className="input-container">
              <input
                onChange={handleChange}
                required
                type="text"
                placeholder="الهاتف"
                name="phone"
                value={orderData.phone}
                className={errors.phone ? "error-input" : ""}
              />
              {errors.phone && (
                <span className="error-message">{errors.phone}</span>
              )}
            </div>
            <input
              type="hidden"
              name="utm_source"
              value={orderData.utm_source}
            />
            <input
              type="hidden"
              name="utm_medium"
              value={orderData.utm_medium}
            />
            <input
              type="hidden"
              name="utm_campaign"
              value={orderData.utm_campaign}
            />
            <input type="hidden" name="utm_term" value={orderData.utm_term} />
            <input
              type="hidden"
              name="utm_content"
              value={orderData.utm_content}
            />
            <input
              className="cta"
              type="submit"
              value=" تأكيد الطلب - الدفع بعد الإستلام"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CodForm;
