import "../../App.css";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom"; // For navigation
import Image1 from "../../assets/carplay-device/image1.webp";
import Image2 from "../../assets/carplay-device/image2.webp";
import Image3 from "../../assets/carplay-device/image3.webp";
import Image4 from "../../assets/carplay-device/image4.jpg";
import Image5 from "../../assets/carplay-device/image5.jpg";
import Image7 from "../../assets/carplay-device/image7.jpg";
import CodForm from "../../components/codForm";
import example1 from "../../assets/carplay-device/example1.webp";
import example2 from "../../assets/carplay-device/example2.webp";
import example3 from "../../assets/carplay-device/example3.webp";
import reviewImage1 from "../../assets/carplay-device/reviews-images/image1.jpg";
import reviewImage2 from "../../assets/carplay-device/reviews-images/image2.jpg";
import deliverySVG from "../../assets/App/delivery.svg";
import { pageView } from "../../Utils/FacebookPixelEvents";
import Testimonials from "../../components/Testimonials/Testimonials";

import ProductImageCarousel from "../../components/ProductImageCarousel/ProductImageCarousel";
import Badges from "../../components/Badges/Badges";

function CarplayDevice() {
  const [productData, setProductData] = useState({
    name: "صندوق كاربلاي لاسلكي",
    quantity: 1,
    withBundle: true,
    slug: "carplay-device",
    selectedBundle: {
      id: 2,
      name: "",
      price: {
        SAR: 199, // Saudi Arabia
        AED: 194, // United Arab Emirates
        BHD: 20, // Bahrain
        MAD: 517, // Morocco
        OMR: 20, // Oman
        QAR: 194, // Qatar
      },
      itemsQuantity: 2,
    },
    bundleData: [
      {
        id: 1,
        name: "جهاز واحد",
        price: {
          SAR: 265,
          AED: 260,
          BHD: 27,
          MAD: 681,
          OMR: 27,
          QAR: 260,
          KWD: 21,
        },
        itemsQuantity: 1,
      },
      {
        id: 2,
        name: "جهازين ( خصم 50% على الجهاز التاني)",
        price: {
          SAR: 398,
          AED: 385,
          BHD: 40,
          MAD: 993,
          OMR: 40,
          QAR: 385,
          KWD: 32,
        },
        itemsQuantity: 2,
      },
    ],
    discount: 50,
    pricing: {
      SAR: 265,
      AED: 260,
      BHD: 27,
      MAD: 681,
      OMR: 27,
      QAR: 260,
      KWD: 21,
    },
    reviews: [
      {
        content:
          "هذا الجهاز اللاسلكي لكاربلاي غيّر تجربتي بالكامل أثناء القيادة. لم أعد أتعامل مع الأسلاك المتشابكة، وكان الإعداد بسيطًا للغاية! أحب أنني أستطيع الآن مشاهدة يوتيوب ونتفليكس عندما أكون في وضع التوقف، مما يجعل وقت الانتظار ممتعًا. أنصح به بشدة لأي شخص يرغب في تحسين تقنية سيارته!",
        rating: 5,
        publisher: "علي بن حمد",
        imgSrc:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSe9K4NCuAOtrrs9_4myX0mqkk4Fni4KhP42A&s",
        // reviewImage: reviewImage1,
      },
      {
        content:
          "كنت مترددة في البداية، لكن هذا الجهاز يعمل بسلاسة تامة! اتصل بهاتفي الآيفون بكل سهولة، والآن يمكنني استخدام جميع التطبيقات بدون الحاجة إلى توصيل هاتفي. والأفضل من ذلك، أنني أستطيع مشاهدة مقاطع يوتيوب ومسلسلاتي المفضلة على نتفليكس أثناء التوقف. إنه ضرورة لأي شخص يبحث عن الراحة والترفيه في السيارة.",
        rating: 5,
        publisher: "ياسمين العربي",
        imgSrc:
          "https://i.pinimg.com/736x/a8/74/6e/a8746e9a8400aa651c8176a68c936c29.jpg",
        // reviewImage: reviewImage2,
      },
      {
        content:
          "صندوق كاربلاي اللاسلكي هو الحل المثالي! كنت أكره توصيل هاتفي في كل مرة، وهذا يجعل التجربة أكثر راحة وسهولة. بالإضافة إلى مشاهدة مقاطع الفيديو على يوتيوب ونتفليكس، جودة الصوت والصورة مذهلة.",
        rating: 5,
        publisher: "محمد عبد الله الراشدي",
        imgSrc:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQV2aQ8RvVQjznmrDLpgSsFoVuMmoWemQiCpkk1i-ML7Idl_5cmmPSLaRsAaYCvPnvw7WE&usqp=CAU",
        // reviewImage: reviewImage2,
      },
      {
        content:
          "ما يعجبني في هذا الجهاز هو أنه يجمع بين البساطة والكفاءة. مجرد الاتصال والتطبيقات تظهر على الشاشة بدون أي تعقيدات. حتى أنني أستطيع مشاهدة الأفلام على نتفليكس أو متابعة الفيديوهات التعليمية على يوتيوب وأنا أنتظر في السيارة!",
        rating: 5,
        publisher: "هدى كامل البكري",
        imgSrc:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7JNSTMznzJsNV1SbRtC_gr70kVfAnFO7D_b-bYL86q3E_UoLutzs5hhOqHB6S8eg5f7I&usqp=CAU",
        // reviewImage: reviewImage2,
      },
      {
        content:
          "اشتريت هذا الجهاز من أجل كاربلاي اللاسلكي، لكن المفاجأة الحقيقية كانت في القدرة على مشاهدة يوتيوب ونتفليكس في السيارة. إنه ليس مجرد جهاز للخرائط والمكالمات، بل هو مركز ترفيه كامل! الآن أصبحت الرحلات الطويلة أكثر متعة بكثير.",
        rating: 5,
        publisher: "يوسف دياب العتيبي",
        imgSrc:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTsmfonyc-fr6Y1bzYzoux3De1LzDwfEeExg&s",
        // reviewImage: reviewImage2,
      },
    ],
  });
  const formRef = useRef(null);
  const reviewsRef = useRef(null);

  const scrollToForm = () => {
    formRef.current.scrollIntoView({ behavior: "smooth" }); // Scroll to the element
  };
  const scrollToReviews = () => {
    reviewsRef.current.scrollIntoView({ behavior: "smooth" }); // Scroll to the element
  };
  const productImages = [Image1, Image2];

  useEffect(() => {
    pageView();
    document.title = productData.name;
  }, []);

  return (
    <div className="App">
      <div className="announcement-bar">
        <p>ضمان 6 أشهر & التوصيل بالمجان</p>
      </div>
      {/* <ProductImageCarousel images={productImages} /> */}
      <div className="image-container">
        <img src={Image7} alt="" />
      </div>
      <div className="bellow-product-image-container">
        <div className="reviews-overview-container" onClick={scrollToReviews}>
          <div className="reviews">
            <i>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                <path
                  fill="#FFD43B"
                  d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                />
              </svg>
            </i>
            <i>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                <path
                  fill="#FFD43B"
                  d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                />
              </svg>
            </i>
            <i>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                <path
                  fill="#FFD43B"
                  d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                />
              </svg>
            </i>
            <i>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                <path
                  fill="#FFD43B"
                  d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                />
              </svg>
            </i>
            {/* <i><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="#FFD43B" d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.7 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z"/></svg></i> */}
          </div>

          <div className="reviews-count">66 تقييم</div>
        </div>
        <div className="main-product-name">
          <h1>{productData.name}</h1>
        </div>

        <div ref={formRef}>
          <CodForm productData={productData} />
        </div>
        <Badges />

        <div className="image-container">
          <img src={Image2} alt="" />
        </div>
        <div className="cta-container">
          <button onClick={scrollToForm} className="cta">
            أطلب المنـتج الحـين
          </button>
        </div>
        <div className="image-container">
          <img src={Image3} alt="" />
        </div>
        <div className="cta-container">
          <button onClick={scrollToForm} className="cta">
            أطلب المنـتج الحـين
          </button>
        </div>
        <div className="image-container">
          <img src={Image4} alt="" />
        </div>
        <div className="cta-container">
          <button onClick={scrollToForm} className="cta">
            أطلب المنـتج الحـين
          </button>
        </div>
        <div ref={reviewsRef}>
          <Testimonials reviews={productData.reviews} />
        </div>
      </div>
    </div>
  );
}

export default CarplayDevice;
