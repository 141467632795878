import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom"; // For navigation

import "./ThankYouPage.css"; // For styling


const ThankYouPage = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const { orderSummary, props, withBundle } = location.state || {}; // Safely accessing state
  
  useEffect(() => {
    return () => {};
  }, []);
  return (
    <div className="thank-you-page">
      <div className="header">
        <div className="thumbs-up"></div>
        <h1>شكرا جزيلا على ثقتكم</h1>
        <p>!شكرا على طلبك</p>
      </div>

      <button className="continue-shopping" onClick={()=>navigate('/egg-holder')}>استمر في التسوق</button>

      <div className="info-grid">
        <div className="info-box">
          <h2>معلومات الدفع</h2>
          <p>الدفع نقدا عند التسليم</p>
        </div>
        <div className="info-box">
          <h2>الوضعيات</h2>
          <p className="unpaid">مبلغ غير مدفوع</p>
        </div>
      </div>

      <div className="order-summary">
        <table>
          <thead>
            <tr>
              <th>المجموع</th>
              <th>الكمية</th>
              <th>ثمن القطعة</th>
              <th>منتج</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="prices">
                {orderSummary.totalPrice}
                {props.currencySymbol}
              </td>
              <td >{orderSummary.quantity}</td>
              <td className="prices">
                {orderSummary.totalPrice} {props.currencySymbol}
              </td>
              <td>{orderSummary.productName}  {props.withBundle?" - "+orderSummary.bundleName:""}</td>

            </tr>
          </tbody>
        </table>
      </div>

      <div className="total-summary">
        <div className="labels">
          <p>المجموع:</p>
          <p>الشحن (شحن مجاني):</p>
          <p>المبلغ الإجمالي:</p>
        </div>
        <div className="numbers">
          <p>
            {orderSummary.totalPrice} {props.currencySymbol}
          </p>
          <p>0 {props.currencySymbol}</p>
          <p>
            {orderSummary.totalPrice} {props.currencySymbol}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;
