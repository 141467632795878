import React, { useEffect } from 'react';
import Slider from 'react-slick';
import "./ProductImageCarousel.css"
import Image1 from "../../assets/carplay-device/image1.webp";
import Image2 from "../../assets/carplay-device/image2.webp";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const ProductImageCarousel = ({ images }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  useEffect(() => {
    
    images.map((image, index) => (
      console.log(image)
    ))
   
  }, []);
  return (

    <div className="slider-container">
      {JSON.stringify(images)}
      <Slider {...settings}>
      {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt="Product Thumbnail"
          />
        ))}
      </Slider>
    </div>
  );
};

export default ProductImageCarousel;