import React from "react";
import "./Badges.css";
const Badges = () => {
  return (
    <div className="badges-container">
      <div className="heading">الضمانات و الاسترجاع</div>
      <div className="icons-container">
        <div className="single-icon-container">
          <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="512.000000pt"
            height="463.099247pt"
            viewBox="0 0 512.000000 463.099247"
            preserveAspectRatio="xMidYMid meet"
          >
            <metadata>
              Created by potrace 1.12, written by Peter Selinger 2001-2015
            </metadata>
            <g
              transform="translate(-0.000000,487.974247) scale(0.100000,-0.100000)"
              fill="#000000"
              stroke="none"
            >
              <path
                d="M891 4870 c-65 -15 -84 -48 -196 -335 l-105 -269 0 -566 c0 -537 1
-568 18 -583 25 -23 78 -21 102 3 19 19 20 33 20 535 l0 515 775 0 775 0 0
-565 c0 -610 -1 -602 52 -639 23 -17 74 -21 104 -9 10 3 103 83 208 176 105
93 196 172 203 174 7 3 88 -62 180 -144 254 -224 240 -213 279 -213 46 0 87
22 102 55 9 19 12 175 12 595 l0 570 775 0 775 0 0 -1655 c0 -1844 6 -1694
-69 -1715 -45 -12 -1361 -14 -1361 -1 0 4 33 11 74 13 113 7 183 55 227 153
47 105 22 224 -63 300 l-40 37 20 21 c54 57 79 189 51 264 -17 47 -59 100 -97
125 -20 14 -295 129 -610 258 -315 128 -571 234 -569 236 2 2 126 41 276 88
157 48 289 95 311 110 76 50 117 167 91 262 -12 46 -70 118 -112 139 -69 36
-115 37 -269 5 -796 -168 -1207 -244 -1477 -275 -173 -20 -209 -18 -204 10 19
107 7 155 -46 193 -36 24 -452 192 -477 192 -41 0 -106 -41 -126 -80 -46 -89
-500 -1220 -500 -1244 0 -35 22 -87 48 -109 28 -26 429 -188 478 -194 49 -6
94 14 126 57 12 17 28 30 36 30 26 0 183 -79 246 -124 34 -24 106 -91 160
-150 54 -58 128 -128 164 -156 62 -46 187 -116 272 -151 31 -13 -3 -14 -334
-17 -481 -3 -466 -9 -466 194 0 94 -3 116 -18 135 -23 28 -73 30 -102 4 -18
-16 -20 -30 -20 -154 0 -122 3 -141 24 -187 13 -28 39 -63 58 -77 76 -59 58
-57 681 -57 l574 0 492 -201 c461 -187 497 -200 556 -200 140 0 255 110 255
243 l0 35 58 7 c70 8 156 48 184 87 l22 29 710 0 c482 0 724 4 751 11 53 14
136 99 153 155 9 32 12 426 12 1741 l0 1699 -109 278 c-60 152 -119 288 -131
301 -12 13 -41 28 -65 34 -56 14 -382 14 -423 0 -56 -19 -64 -101 -13 -128 12
-7 95 -11 209 -11 l190 0 47 -122 c26 -68 62 -160 80 -205 18 -45 30 -84 27
-87 -3 -3 -346 -6 -763 -6 l-759 0 -5 23 c-3 12 -25 105 -48 207 l-43 185 295
3 c287 2 296 3 318 24 41 38 26 98 -28 117 -38 13 -2953 14 -3008 1z m1499
-146 c0 -13 -91 -397 -96 -405 -6 -11 -1524 -12 -1524 -2 0 6 113 302 146 381
l13 32 730 0 c402 0 731 -3 731 -6z m821 -199 c25 -110 45 -203 42 -207 -6
-12 -803 -10 -803 1 0 5 20 97 45 204 25 108 45 198 45 201 0 4 140 5 312 4
l312 -3 47 -200z m69 -871 c0 -483 -1 -516 -17 -503 -9 8 -82 72 -162 143 -80
70 -161 140 -178 153 -38 30 -92 33 -129 8 -24 -15 -251 -213 -331 -289 l-33
-30 0 517 0 517 425 0 425 0 0 -516z m-2447 -957 c155 -63 177 -75 174 -92 -5
-26 -457 -1137 -468 -1149 -10 -12 -391 144 -387 159 2 6 109 271 238 588 184
452 238 576 250 572 8 -3 95 -38 193 -78z m2196 -22 c57 -29 58 -125 2 -154
-11 -7 -181 -61 -376 -122 -195 -60 -366 -115 -380 -121 -30 -14 -45 -54 -33
-89 11 -33 -28 -16 743 -329 341 -139 632 -261 647 -272 57 -39 58 -133 1
-178 -56 -44 -57 -44 -543 154 -250 103 -464 186 -476 186 -58 0 -90 -79 -48
-120 10 -10 260 -116 554 -235 374 -152 544 -226 564 -245 20 -20 30 -40 33
-74 5 -42 3 -48 -30 -81 -33 -33 -53 -40 -102 -35 -11 1 -264 101 -563 222
-588 239 -577 237 -611 177 -21 -38 -8 -75 37 -98 20 -10 206 -87 412 -171
489 -199 495 -201 514 -239 42 -80 -14 -171 -105 -171 -12 0 -226 83 -475 185
-250 102 -461 185 -468 185 -7 0 -25 -9 -40 -21 -32 -25 -36 -77 -7 -103 10
-9 180 -82 377 -163 198 -80 371 -154 386 -165 32 -22 52 -77 43 -117 -3 -15
-19 -41 -35 -56 -56 -54 -49 -56 -820 259 -388 158 -732 301 -765 318 -83 42
-139 85 -215 165 -190 200 -283 270 -442 335 l-77 31 15 36 c8 20 78 191 156
381 77 190 149 365 159 390 l20 46 93 -4 c119 -5 232 7 571 64 242 41 892 170
1115 222 105 25 133 26 169 7z"
              />
            </g>
          </svg>
          <div className="text"> الدفع عند الإستلام </div>
        </div>
        <div className="single-icon-container">
          <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="405.777111pt"
            height="463.887349pt"
            viewBox="0 0 405.777111 463.887349"
            preserveAspectRatio="xMidYMid meet"
          >
            <metadata>
              Created by potrace 1.12, written by Peter Selinger 2001-2015
            </metadata>
            <g
              transform="translate(-53.222889,487.974117) scale(0.100000,-0.100000)"
              fill="#000000"
              stroke="none"
            >
              <path
                d="M1923 4863 c-88 -30 -163 -118 -277 -321 -103 -183 -124 -198 -355
-247 -242 -51 -323 -90 -381 -181 -53 -84 -60 -167 -34 -392 19 -162 14 -228
-20 -285 -13 -23 -71 -93 -128 -155 -198 -213 -236 -329 -158 -488 31 -64 58
-99 191 -247 134 -149 141 -171 114 -408 -32 -285 -3 -390 135 -480 l24 -16
-219 -379 c-121 -208 -221 -391 -223 -407 -5 -34 21 -75 54 -83 16 -4 137 23
355 81 181 49 331 86 332 84 2 -2 43 -150 90 -329 74 -278 90 -328 112 -347
31 -27 65 -29 94 -6 11 10 112 178 225 373 l204 355 60 16 c33 10 128 47 212
84 227 100 247 100 492 -12 66 -29 148 -61 183 -70 l63 -17 198 -346 c109
-190 211 -357 226 -372 45 -47 106 -30 127 35 6 17 47 167 91 331 44 164 82
301 85 304 3 3 150 -34 328 -81 177 -48 331 -87 343 -87 26 0 62 33 70 64 7
30 17 12 -237 447 -146 251 -207 365 -198 367 8 3 39 30 70 61 92 91 105 174
74 457 -24 212 -15 238 142 406 149 162 203 257 203 358 0 107 -47 191 -205
366 -60 65 -117 139 -128 164 -23 51 -26 153 -8 275 16 109 14 255 -6 315 -22
68 -86 138 -158 174 -31 15 -107 38 -168 52 -285 62 -310 72 -366 148 -21 28
-62 94 -91 147 -121 224 -218 308 -355 308 -72 1 -132 -18 -305 -94 -229 -101
-255 -101 -501 11 -185 84 -281 101 -376 67z m241 -187 c44 -19 118 -51 164
-71 45 -20 118 -44 161 -53 75 -15 81 -15 162 5 47 11 141 46 209 78 69 31
153 64 188 73 62 17 63 16 106 -6 51 -28 81 -67 177 -234 141 -245 186 -275
513 -343 139 -28 202 -57 233 -104 20 -29 23 -47 23 -120 0 -47 -5 -117 -11
-156 -6 -38 -10 -115 -11 -170 0 -159 29 -215 226 -432 94 -103 125 -156 126
-211 0 -65 -22 -98 -193 -288 -152 -168 -176 -246 -151 -483 38 -360 33 -366
-339 -445 -238 -50 -289 -93 -448 -375 -41 -73 -79 -127 -108 -152 -39 -35
-50 -39 -95 -39 -51 0 -96 16 -291 103 -211 95 -287 90 -560 -30 -117 -52
-163 -68 -210 -71 -58 -4 -61 -3 -105 36 -30 26 -67 79 -109 153 -158 280
-210 324 -443 374 -206 45 -240 55 -282 77 -79 44 -90 109 -62 371 28 256 5
313 -218 556 -94 103 -126 158 -126 215 0 54 28 101 127 210 190 209 224 273
225 416 1 47 -5 132 -12 190 -7 58 -11 140 -9 182 4 65 8 81 29 103 39 42 87
61 221 90 190 40 239 54 302 88 78 41 127 100 225 269 92 160 112 186 162 217
45 28 95 23 204 -23z m-839 -3111 c138 -30 196 -55 234 -104 15 -20 63 -98
106 -173 73 -129 136 -209 197 -254 l26 -19 -141 -244 -140 -244 -73 269 c-81
305 -80 301 -105 314 -30 17 -52 12 -321 -60 -144 -39 -263 -68 -265 -66 -8 8
342 606 355 606 7 0 64 -11 127 -25z m2783 -277 c95 -165 171 -303 168 -305
-2 -3 -121 27 -264 66 -260 71 -286 76 -317 65 -26 -10 -39 -46 -110 -316 -38
-142 -71 -258 -74 -258 -3 0 -68 107 -143 238 -137 236 -138 237 -115 251 58
38 127 127 204 262 94 165 124 202 190 231 39 17 241 66 278 67 6 0 88 -135
183 -301z"
              />
              <path
                d="M2375 4165 c-133 -21 -234 -53 -360 -115 -370 -182 -620 -518 -687
-924 -16 -95 -15 -303 1 -396 91 -520 503 -935 1019 -1025 110 -19 316 -19
428 0 306 54 595 233 784 486 109 146 199 357 231 539 16 93 17 301 1 396 -53
319 -226 607 -481 800 -100 76 -293 174 -401 204 -175 48 -371 61 -535 35z
m331 -156 c233 -32 432 -130 606 -297 440 -423 446 -1116 13 -1547 -136 -135
-298 -229 -491 -282 -91 -25 -114 -28 -274 -28 -160 0 -183 3 -275 28 -657
181 -996 899 -710 1504 58 123 105 192 195 287 247 261 590 383 936 335z"
              />
              <path
                d="M3230 3443 c-8 -3 -201 -190 -427 -416 l-413 -411 -237 236 c-161
159 -248 239 -267 244 -56 14 -107 -40 -93 -97 4 -13 131 -149 285 -301 256
-257 280 -278 312 -278 33 0 65 30 488 453 402 403 452 457 452 484 0 45 -9
62 -41 78 -30 16 -35 17 -59 8z"
              />
            </g>
          </svg>
          <div className="text"> ضمان المنتج</div>
        </div>
        <div className="single-icon-container">
          <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="447.323224pt"
            height="260.030909pt"
            viewBox="0 0 447.323224 260.030909"
            preserveAspectRatio="xMidYMid meet"
          >
            <metadata>
              Created by potrace 1.12, written by Peter Selinger 2001-2015
            </metadata>
            <g
              transform="translate(-32.176776,386.030909) scale(0.100000,-0.100000)"
              fill="#000000"
              stroke="none"
            >
              <path
                d="M792 3837 c-27 -29 -29 -77 -3 -108 l19 -24 1204 -5 c968 -4 1207 -8
1226 -18 33 -19 62 -71 62 -112 0 -19 -56 -368 -124 -775 -129 -775 -130 -782
-86 -805 24 -13 66 -13 90 0 11 6 24 27 30 48 6 20 55 309 110 642 55 333 103
617 106 633 l6 27 357 0 c402 0 416 -2 493 -70 51 -44 39 -23 208 -389 77
-165 135 -306 141 -337 6 -31 9 -176 7 -337 l-3 -282 -28 -47 c-17 -30 -44
-57 -73 -74 -41 -24 -56 -27 -145 -28 l-100 -1 -17 50 c-76 223 -303 348 -525
290 -81 -21 -132 -50 -194 -107 -59 -57 -92 -109 -117 -185 l-18 -53 -752 0
-752 0 -17 54 c-22 70 -84 162 -137 204 -22 18 -71 48 -108 65 -62 30 -74 32
-172 32 -121 -1 -181 -19 -265 -83 -82 -62 -124 -126 -170 -259 -3 -10 -38
-13 -129 -13 -123 0 -125 0 -145 -26 -28 -35 -26 -67 3 -101 24 -27 26 -28
149 -31 l124 -4 13 -41 c52 -178 228 -307 421 -307 187 0 355 122 414 301 l17
49 754 0 c595 0 755 -3 758 -12 44 -152 134 -258 263 -309 82 -33 252 -33 333
-1 116 46 215 151 248 263 l16 56 112 6 c151 8 209 31 290 111 43 43 68 79 85
121 24 59 24 65 24 385 0 216 -4 342 -12 375 -15 63 -304 684 -341 733 -42 55
-137 120 -209 142 -60 18 -93 20 -419 20 l-354 0 0 68 c0 112 -60 210 -163
264 l-42 23 -1221 3 -1220 2 -22 -23z m806 -1893 c97 -48 153 -139 153 -249 0
-192 -175 -320 -361 -264 -68 20 -154 108 -174 178 -20 68 -20 104 -1 168 46
156 237 240 383 167z m2376 1 c50 -24 100 -74 128 -128 18 -33 23 -59 23 -122
0 -70 -4 -87 -29 -133 -52 -94 -137 -144 -243 -144 -116 -1 -223 75 -259 184
-20 62 -15 158 11 213 26 54 96 120 148 139 56 21 169 16 221 -9z"
              />
              <path
                d="M632 3467 c-40 -42 -24 -105 32 -127 17 -6 188 -10 471 -10 492 0
478 -2 491 67 5 27 1 37 -24 63 l-30 30 -459 0 -460 0 -21 -23z"
              />
              <path
                d="M3510 3190 c-19 -19 -20 -33 -20 -383 l0 -364 23 -21 c23 -22 27 -22
402 -22 365 0 380 1 423 21 57 28 94 84 100 153 4 48 -4 72 -99 301 -56 138
-111 262 -122 278 -38 55 -49 57 -382 57 -292 0 -306 -1 -325 -20z m675 -360
c50 -121 90 -230 90 -242 l0 -23 -312 -3 -313 -2 0 245 0 245 223 0 222 0 90
-220z"
              />
              <path
                d="M345 3036 c-39 -39 -27 -103 22 -125 16 -8 183 -11 539 -11 l515 0
24 25 c32 31 32 66 1 102 l-24 28 -526 3 -526 3 -25 -25z"
              />
              <path
                d="M636 2607 c-34 -34 -34 -80 -1 -112 l24 -25 263 -2 c351 -3 339 -4
366 27 29 34 28 67 -2 102 l-24 28 -301 4 -300 3 -25 -25z"
              />
            </g>
          </svg>
          <div className="text"> شحن سريع </div>
        </div>
      </div>
    </div>
  );
};

export default Badges;
