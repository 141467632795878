// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCd-XcMyHEmYoHQZ4cViZn7YeemWdE6UyU",
  authDomain: "cod-funnel-server.firebaseapp.com",
  databaseURL: "https://cod-funnel-server-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "cod-funnel-server",
  storageBucket: "cod-funnel-server.appspot.com",
  messagingSenderId: "276295575747",
  appId: "1:276295575747:web:eaa393063686c8460c1a76",
  measurementId: "G-E99SXF1YGF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default app