import { useEffect } from "react";

const QueryForm = ({ onUtmUpdate, reset = false }) => {
  useEffect(() => {
    const queryForm = () => {
      // Get the current URL
      const self = window.location.toString();
      const querystring = self.split("?");

      // Check if there are query parameters
      if (querystring.length > 1) {
        const pairs = querystring[1].split("&");

        // Store query parameters in sessionStorage
        pairs.forEach((pair) => {
          const [key, val] = pair.split("=");
          if (reset || sessionStorage.getItem(key) === null) {
            sessionStorage.setItem(key, decodeURIComponent(val));
          }
        });
      }

      // Update hidden and text fields with sessionStorage data
      const hiddenFields = document.querySelectorAll("input[type=hidden]");

      hiddenFields.forEach((field) => {
        const param = sessionStorage.getItem(field.name);
        if (param) {
          onUtmUpdate(field.name, param);
        }
      });
    };

    // Delay form query extraction by 3 seconds
    const timer = setTimeout(queryForm, 3000);

    // Cleanup the timeout
    return () => clearTimeout(timer);
  }, [reset]);
  return null;
};

export default QueryForm;
